import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Grid, makeStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FC } from '../../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '6rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  perkCont: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    minHeight: '145px',
    marginTop: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      minHeight: '165px',
    },
  },
  fontColor: {
    color: '#4B5B69',
    lineHeight: 1.2,
    animation: '$fadeIn 1s',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      animation: 'none',
      fontSize: '1rem',
    },
  },
  backgroundIcon: {
    backgroundColor: '#fff',
    border: '2px solid #E5EAF4',
    boxShadow: '0 0px 20px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '75px',
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    animation: '$fadeIn 1s',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 100,
    },
  },
}));

const UKBenefits: FC = () => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
      className={classes.root}
    >
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Critical illness, income protection and death in service cover"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'umbrella']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Critical Illness, <br />
            Income Protection <br />
            and Death in Service Cover
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Generous Holiday Entitlement"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'calendar']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Generous Holiday <br />
            Entitlement
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Access to a private GP service"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'stethoscope']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Access to a <br />
            Private GP Service
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Discounted Private Healthcare"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'shield-plus']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Discounted <br />
            Private Healthcare
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Headspace App Subscription"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'moon-cloud']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Headspace App <br />
            Subscription
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Paid for Counseling Sessions"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'comments']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Paid for <br />
            Counseling Sessions
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Enhanced maternity, paternity and adoption pay"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'baby-carriage']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Enhanced Maternity, <br />
            Paternity and <br />
            Adoption Pay
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Technology Benefit"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'microchip']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Technology Benefit
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Personal Travel Discounts"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'plane']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Personal Travel
            <br />
            Discounts
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Paid time off for volunteering"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'hand-holding-heart']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Paid Time Off <br />
            for Volunteering
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Employee Discount Scheme"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'badge-percent']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Employee <br />
            Discount Scheme
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Training or Certification Reimbursement"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'graduation-cap']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Training or <br />
            Certification <br />
            Reimbursement
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UKBenefits;
