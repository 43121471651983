import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Grid, makeStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FC } from '../../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '6rem',
  },
  perkCont: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    minHeight: '145px',
    marginTop: '1.5rem',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 100,
    },
  },
  fontColor: {
    color: '#4B5B69',
    lineHeight: 1.2,
    animation: '$fadeIn 1s',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      animation: 'none',
      fontSize: '1rem',
    },
  },
  backgroundIcon: {
    backgroundColor: '#fff',
    border: '2px solid #E5EAF4',
    boxShadow: '0 0px 20px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '75px',
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    animation: '$fadeIn 1s',
  },
}));

const USBenefits: FC = () => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
      className={classes.root}
    >
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="generous time off"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'island-tropical']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Generous
            <br />
            Time Off
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="8 paid holidays"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'calendar']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            8 Paid Holidays
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="bonding leave"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'baby-carriage']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Bonding Leave
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="401k match"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'piggy-bank']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            401k Match
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="employee assistance program"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'circle-heart']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Employee Assistance
            <br />
            and Discount Program
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Headspace App Subscription"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'moon-cloud']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Headspace App
            <br />
            Subscription
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Personal Travel Discounts"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'plane']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Personal Travel
            <br />
            Discounts
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="comprehensive medical, dental & vision packages"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'shield-plus']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Comprehensive
            <br />
            Medical, Dental &<br />
            Vision Packages
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="supplemental military pay"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'circle-star']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Employer Paid Life,
            <br />
            Disability & Supplemental Military and Disability Pay
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="tuition reimbursement"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'graduation-cap']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Tuition
            <br />
            Reimbursement
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="$1K employee referral bonus"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'sack-dollar']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Employee
            <br />
            Referral Bonuses
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="employee discount program"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'badge-percent']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Enhanced Voluntary
            <br />
            Benefit Offerings
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default USBenefits;
